.tableTime {
  thead {
    th {
      border-bottom: none;
    }
  }

  tbody {
    td:last-child {
      border-left: none;
    }
  }

  td.timeEntryBox {
    min-width: 5em;
  }

  td.timeTotalBox {
    min-width: 5em;
  }

  td.actionBox {
    min-width: 3.5em;
  }

  td > input,
  td > select {
    border-radius: 0;
    border: 0;
    background-color: transparent;
  }
}
