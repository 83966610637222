$font-size-base: 1rem !default;

$theme-colors: (
        "primary": #0589ae,
        "light-primary": #e3f1ff,
        "success": #679925,
        "danger": #ff0000,
        "warning": #d89f52,
        "info": #2ca9ff,
        "gray": #bfbfbf,
        "light-gray": #f0f0f0,
        "gci": #2ca9ff
);

@import '../node_modules/bootstrap/scss/bootstrap';

.bg-danger,
.bg-dark,
.bg-info,
.bg-primary,
.bg-secondary,
.bg-success,
.bg-warning,
.bg-gci {
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  @extend .text-primary;
}

.row-tight-cols {
  .col,
  [class*='col-'] {
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.form-tight-cols {
  .col,
  [class*='col-'],
  label {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.form-group > label {
  font-weight: bold;
}

[class^="inline-label-"] {
  @extend .font-weight-bold;
}

.inline-label {
  @extend .font-weight-bold;
  @extend .text-right;
}

#root > .App > .navbar.fixed-top {
  .backMenuButton {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-link.active:not(.backMenuButton) {
    border-bottom: 4px solid theme-color("primary");
    margin-bottom: -4px;

  }

  .navbar-brand > img {
    max-width: 100%;
    height: 1.25em;
    object-fit: contain;
  }
}

.input-group-filter {
  border: none;

  [class^="input-group-"] {
    border: none;
    color: lightgray;
    background: white;
  }

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
  }
}

.dropdown-item > svg {
  margin-right: 0.5em;
}

.fa-fw {
  margin-right: 0.5em;
}

tr.active {
  background-color: theme-color("primary") !important;
  color: white !important;
}

.dropdown-toggle::after {
  margin-left: 0.5em !important;
}

.no-underline {
  text-decoration: none !important;
}

.no-underline:hover {
  text-decoration: none !important;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: -1px !important;
}

.nav-tabs .nav-item {
  margin-bottom: -2px !important;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link:not(.active) {
  border-color: #e9ecef #e9ecef #dee2e6;
  border-bottom-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: theme-color("primary") !important;
  border-top: 4px solid theme-color("primary");
  border-bottom-color: white !important;
  padding-top: 5px;
  font-weight: bold;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .dropdown-menu:not(.no-shadow) {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    max-height: 340px;
    overflow-y: auto;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .dropdown-menu:not(.no-shadow) {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    max-height: 540px;
    overflow-y: auto;
  }

  .width-xl-md-larger {
    width: 10em !important;
  }
}

.form-sm {
  > .form-group {
    @extend .row;

    > label {
      @extend .col-sm-3;
      @extend .form-control-plaintext;
      font-weight: bold;
    }

    > div:not(.short):not(.medium) {
      @extend .col-sm-9;
    }

    > div.short {
      @extend .col-sm-3;
    }

    > div.medium {
      @extend .col-sm-6;
    }

    > div.offset {
      @extend .offset-sm-3;
    }
  }
}

.form-sm-label {
  > .form-group {
    @extend .row;

    > label {
      @extend .col-sm-2;
      @extend .form-control-plaintext;
      font-weight: bold;
    }

    > div:not(.short):not(.medium) {
      @extend .col-sm-10;
    }

    > div.short {
      @extend .col-sm-4;
    }

    > div.medium {
      @extend .col-sm-6;
    }

    > div.offset {
      @extend .offset-sm-2;
    }
  }
}

.form-half {
  > .form-group {
    @extend .row;

    > label {
      @extend .col-sm-6;
      @extend .form-control-plaintext;
      @extend .text-right;
      font-weight: bold;
    }

    > div {
      @extend .col-sm-6;
    }

    > div.short {
      @extend .col-sm-3;
    }

    > div.offset {
      @extend .offset-sm-6;
    }
  }
}

.form-condensed {
  input,
  select,
  .input-dd > button {
    padding: 1px 4px;
    height: calc(1.5em + 0.75rem + 2px - 10px);
  }

  textarea {
    padding: 1px 4px;
  }

  .form-group {
    margin-bottom: 2px;

    > label {
      padding: 1px 0;
    }
  }

  .form-half > .form-group {
    @extend .my-1;
  }

  .form-control-plaintext {
    padding: 1px 4px;
  }

  .input-group-text {
    padding: 1px 4px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .form-sm,
  .form-sm-label {
    > .form-group {
      > label {
        text-align: right;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .narrow {
    margin-left: 8.33334%;
    margin-right: 8.33334%;
  }

  .narrower {
    margin-left: 16.66667%;
    margin-right: 16.66667%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .narrowed {
    margin-left: 4.16666%;
    margin-right: 4.16666%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .narrowed {
    margin-left: 8.33334%;
    margin-right: 8.33334%;
  }

  .narrow {
    margin-left: 25%;
    margin-right: 25%;
  }

  .narrower {
    margin-left: 33.33334%;
    margin-right: 33.33334%;
  }
}

.table-inputs {
  td > input,
  td > select {
    border-radius: 0;
    border: 0;
    background-color: transparent;
  }
}

table.table-sortable thead th:hover {
  background-color: var(--gray) !important;
}

.tsBorderStandard {
  tr {
    border: 1px solid #dee2e6;

    td, th {
      border: 1px solid #dee2e6;
    }
  }
}

.tsCellIncluded {
  border-top: 4px solid black !important;
  border-bottom: 4px solid black !important;
  font-weight: bold !important;
  padding: 2px !important;
}

.tsCellIncludedStart {
  border-left: 4px solid black !important;
}

.tsCellIncludedEnd {
  border-right: 4px solid black !important;
}

.border-right-bold {
  border-right-width: 3px;
}

.border-left-bold {
  border-left-width: 3px;
}

.border-right-bold {
  border-right-width: 3px;
}

.inputSearch {
  border: none;
}

.lrExpanderButton {
  top: 0;
  right: 1.5em;
  transition-duration: 0.5s;
  transition-property: transform;
}

.lrExpanderDiv {
  transition-duration: 0.5s;
  transition-property: width;
}

.tab-content {
  overflow-x: hidden;
}

.border-bottom-dark + .border-bottom-dark {
  border-top: black solid 1px;
}

.tab-pane {
  overflow-x: hidden;
}
