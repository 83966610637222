.tableMile {
  td.amountTotals {
    min-width: 5em;
  }

  td.actionBox {
    min-width: 3.5em;
  }

  td.odometer {
    width: 7em;
  }

  td > input,
  td > select {
    border-radius: 0;
    border: 0;
    background-color: transparent;
  }
}
