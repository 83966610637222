.none-xs {
    display: none;
}

@media (min-width: 768px) {
  .none-sm {
      display: none;
  }
}

@media (min-width: 992px) {
  .none-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .none-lg {
    display: none;
  }
}

.backMenuTitle {
  position:absolute;
  //display: block;
  text-align: center;
  left: 25%;
  right: 25%;
  top: 1rem;
  font-weight: bold;
  color: white;
}

.backMenuButton {
  cursor: pointer;
}
